import React from "react";

import "./App.css";
import Home from "./pages";

function App() {
	return (
		<>
			<title>Josef Anthony</title>
			<Home />
		</>
	);
}

export default App;
