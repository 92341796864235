export const sliderData = [
	{
		image: require("../../../images/sliderMedia/pic1.jpg"),
	},
	{
		image: require("../../../images/sliderMedia/pic2.png"),
	},
	{
		image: require("../../../images/sliderMedia/pic3.jpg"),
	},
	{
		image: require("../../../images/sliderMedia/pic17.png"),
	},
	{
		image: require("../../../images/sliderMedia/pic5.jpg"),
	},
	{
		image: require("../../../images/sliderMedia/pic6.jpg"),
	},
	{
		image: require("../../../images/sliderMedia/pic7.jpg"),
	},
	{
		image: require("../../../images/sliderMedia/pic8.png"),
	},
	{
		image: require("../../../images/sliderMedia/pic9.png"),
	},
	{
		image: require("../../../images/sliderMedia/pic10.png"),
	},
	{
		image: require("../../../images/sliderMedia/pic11.png"),
	},
	{
		image: require("../../../images/sliderMedia/pic12.png"),
	},
	{
		image: require("../../../images/sliderMedia/pic13.png"),
	},
	{
		image: require("../../../images/sliderMedia/pic14.png"),
	},
	{
		image: require("../../../images/sliderMedia/pic15.png"),
	},
	{
		image: require("../../../images/sliderMedia/pic16.png"),
	},
];
